var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"from_outer"},[_c('div',[_c('el-form',{ref:"formName",attrs:{"rules":_vm.rules,"label-width":"120px","model":_vm.formLabelAlign}},[_c('el-row',[_c('el-col',{attrs:{"span":12}},[_c('el-form-item',{attrs:{"label":"名称：","prop":"name","rules":[{ required: true, message: '请输入名称' }]}},[_c('el-input',{staticClass:"form-inp",attrs:{"placeholder":"请输入名称","size":"mini"},model:{value:(_vm.formLabelAlign.name),callback:function ($$v) {_vm.$set(_vm.formLabelAlign, "name", $$v)},expression:"formLabelAlign.name"}})],1)],1),_c('el-col',{attrs:{"span":12}},[_c('el-form-item',{attrs:{"label":"分类："}},[_c('el-cascader',{staticClass:"form-inp",attrs:{"options":_vm.catesList,"props":{
                checkStrictly: true,
                label: 'name',
                children: 'items',
                value: 'id',
              }},on:{"change":_vm.dataChange},model:{value:(_vm.formLabelAlign.cate_id),callback:function ($$v) {_vm.$set(_vm.formLabelAlign, "cate_id", $$v)},expression:"formLabelAlign.cate_id"}})],1)],1),_c('el-form-item',{staticStyle:{"margin-top":"40px"},attrs:{"label":"缩略图："}},[_c('div',{staticClass:"tag-sty"},[_c('uploadingimg',{model:{value:(_vm.img_List),callback:function ($$v) {_vm.img_List=$$v},expression:"img_List"}})],1)]),_c('el-col',{attrs:{"span":24}},[_c('el-upload',{staticClass:"avatar-uploader",attrs:{"name":"file","action":this.$store.state.uploadImage,"data":{
              path_name: 'danger_icon',
            },"headers":this.$tools.config(),"show-file-list":false,"on-success":_vm.uploadSuccess,"before-upload":_vm.beforeupload}}),_c('el-form-item',{attrs:{"label":"详情：","prop":"detail"}},[_c('quill-editor',{ref:"myQuillEditor",staticClass:"qui",attrs:{"options":_vm.editorOption},model:{value:(_vm.formLabelAlign.detail),callback:function ($$v) {_vm.$set(_vm.formLabelAlign, "detail", $$v)},expression:"formLabelAlign.detail"}})],1)],1)],1)],1),_c('div',{staticStyle:{"text-align":"center","margin":"20px 0"}},[_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.on_preserve('formName')}}},[_vm._v(_vm._s(_vm.type_name))]),_c('el-button',{on:{"click":_vm.route_go}},[_vm._v("返回")])],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }