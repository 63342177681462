<template>
  <div class="from_outer">
    <div>
      <el-form
        :rules="rules"
        ref="formName"
        label-width="120px"
        :model="formLabelAlign"
      >
        <el-row>
          <el-col :span="12">
            <el-form-item
              label="名称："
              prop="name"
              :rules="[{ required: true, message: '请输入名称' }]"
            >
              <el-input
                placeholder="请输入名称"
                size="mini"
                class="form-inp"
                v-model="formLabelAlign.name"
              ></el-input>
            </el-form-item>
          </el-col>
          <!-- <el-col :span="12">
            <el-form-item
              label="教育方式："
              prop="type"
              :rules="[{ required: true, message: '请选择人员状态' }]"
            >
              <el-radio
                v-for="item in status_list"
                :key="item.id"
                v-model="formLabelAlign.type"
                :label="item.id"
                >{{ item.name }}</el-radio
              >
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <div class="execute">
              <el-form-item label="学习周期：">
                <el-select
                  v-model="formLabelAlign.check_times"
                  placeholder="请选择学习周期"
                  class="form-inp"
                >
                  <el-option
                    v-for="item in check_times"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item
                label-width="0px"
                v-if="formLabelAlign.check_times == 5"
                prop="times_days"
              >
                <el-input
                  style="width: 200px"
                  placeholder="请输入天数"
                  size="mini"
                  class="form-inp"
                  v-model.number="formLabelAlign.times_days"
                >
                  <template slot="append">天</template>
                </el-input>
              </el-form-item>
            </div>
          </el-col>

          <el-col :span="12">
            <el-form-item label="评估标准：">
              <el-select
                v-model="formLabelAlign.level_type"
                placeholder="请选择评估标准"
                class="form-inp"
              >
                <el-option
                  v-for="item in estimate_type"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="考核分数：">
              <el-input
                type="number"
                placeholder="请输入考核分数"
                size="mini"
                class="form-inp"
                v-model="formLabelAlign.score"
              ></el-input>
            </el-form-item>
          </el-col> -->

          <el-col :span="12">
            <el-form-item label="分类：">
              <el-cascader
                class="form-inp"

                v-model="formLabelAlign.cate_id"
                :options="catesList"
                :props="{
                  checkStrictly: true,
                  label: 'name',
                  children: 'items',
                  value: 'id',
                }"
                @change="dataChange"
              >
              </el-cascader>
            </el-form-item>
          </el-col>

          
          <!-- <el-col :span="24">
            <el-form-item label="部门：" prop="department_id">
              <BepartmentMulti ref="BepartmentMulti" />
            </el-form-item>
          </el-col> -->
          <el-form-item label="缩略图：" style="margin-top: 40px;">
            <div class="tag-sty">
              <uploadingimg v-model="img_List" />
            </div>
          </el-form-item>
          <el-col :span="24">
            <el-upload
              class="avatar-uploader"
              name="file"
              :action="this.$store.state.uploadImage"
              :data="{
                path_name: 'danger_icon',
              }"
              :headers="this.$tools.config()"
              :show-file-list="false"
              :on-success="uploadSuccess"
              :before-upload="beforeupload"
            ></el-upload>

            <el-form-item label="详情：" prop="detail">
              <quill-editor
                class="qui"
                v-model="formLabelAlign.detail"
                ref="myQuillEditor"
                :options="editorOption"
              ></quill-editor>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div style="text-align: center; margin: 20px 0">
        <el-button @click="on_preserve('formName')" type="primary">{{
          type_name
        }}</el-button>
        <el-button @click="route_go">返回</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { eductionsaveInfo, eductionsave } from "../../assets/request/api";
import BepartmentMulti from "../../components/Bepartment_multi.vue";
import { quillEditor } from "vue-quill-editor";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import uploadingFile from "../../components/uploading/uploading_File.vue";
import uploadingimg from "../../components/uploading/uploading_img.vue";

export default {
  name: "thought_redact",
  components: {
    uploadingFile,
    quillEditor,
    BepartmentMulti,
    uploadingimg
  },
  data() {
    let skyRuel = (rule, value, callback) => {
      if (!this.$tools.verify_sky(value)) {
        callback(new Error("请输入正确天数"));
      } else {
        callback();
      }
    };
    return {
      content: "",
      type_name: "保存",
      //要修改的id
      id: "",
      img_List: [],
      enable_list: [],
      status_list: [],
      check_times: [],
      estimate_type: [],
      catesList: [],
      formLabelAlign: {
        name: "",
        img:"",
        // is_enable: "",
        // type: "",
        detail: "",
        // reponse_id: "",
        // begin_time: "",
        // end_time: "",
        // check_times: "",
        // score: 0,
        // department_id: "",
        // level_type: "",
        // times_days: "",
        cate_id: "",
      },
      rules: {
        times_days: [
          { validator: skyRuel, trigger: "blur" },
          { required: true, message: "请输入天数" },
        ],
      },
      timeData: [],
      teacher_Data: [],
      TSmaster_id: "",
      search_Data: "",
      //富文本信息
      editorOption: {
        placeholder: "",
        theme: "snow", // or 'bubble'
        modules: {
          toolbar: {
            container: [
              ["bold", "italic", "underline", "strike"], // toggled buttons
              [{ header: 1 }, { header: 2 }], // custom button values
              [{ list: "ordered" }, { list: "bullet" }],
              [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
              [{ direction: "rtl" }], // text direction
              [{ size: ["small", false, "large", "huge"] }], // custom dropdown
              [{ header: [1, 2, 3, 4, 5, 6, false] }],
              [{ color: [] }, { background: [] }], // dropdown with defaults from theme
              [{ font: [] }],
              [{ align: [] }],
              ["image"],
              ["clean"],
            ],
            handlers: {
              image: function (value) {
                if (value) {
                  // 触发input框选择图片文件
                  document.querySelector(".avatar-uploader input").click();
                } else {
                  this.quill.format("image", false);
                }
              },
            },
          },
        },
      },
    };
  },
  created() {
    let { id } = this.$route.query;
    this.way_show(id);
  },
  methods: {
    beforeupload() {
      this.$tools.shade_open();
    },
    //替换富文本上传
    uploadSuccess(res, file) {
      this.$tools.close_open();
      // 获取富文本组件实例
      let quill = this.$refs.myQuillEditor.quill;
      // 如果上传成功
      if (res.code == 0) {
        let length = quill.getSelection().index;
        // 插入图片  res.url为服务器返回的图片地址
        quill.insertEmbed(length, "image", res.data.url);
        // 调整光标到最后
        quill.setSelection(length + 1);
      } else {
        this.$message.error("图片插入失败");
      }
    },
    way_show(id) {
      let froms = {};
      if (id) froms.id = id;
      eductionsaveInfo(froms).then((res) => {
        let {
          info,
          // type_list,
          // enable_list,
          // check_times,
          // estimate_type,
          catesList,
        } = res.data;
        // this.estimate_type = estimate_type;
        // this.status_list = type_list;
        // this.enable_list = enable_list;
        // this.check_times = check_times;
        this.catesList = catesList;
        if (id) {
          this.id = id;
          this.type_name = "修改";
          let { formLabelAlign } = this;
          let {
            name,
            type,
            img,
            // student_id,
            // check_times,
            begin_time,
            end_time,
            detail,
            // score,
            department_id,
            // level_type,
            // times_days,
            cate_id
          } = info;
          formLabelAlign.type = type;
          this.img_List = img?img.split(','):[]
          // formLabelAlign.score = score;
          formLabelAlign.name = name;
          // formLabelAlign.student_id = student_id;
          // formLabelAlign.check_times = check_times;
          // formLabelAlign.begin_time = begin_time;
          // formLabelAlign.end_time = end_time;
          // formLabelAlign.end_time = end_time;
          formLabelAlign.detail = detail;
          // formLabelAlign.level_type = level_type;
          // formLabelAlign.times_days = times_days;
          formLabelAlign.cate_id = cate_id;


          this.timeData = [begin_time, end_time];
          let item = department_id.split(",");
          this.$refs.BepartmentMulti.way_show(item);
        }
      });
    },
    on_timeData() {
      let { timeData, formLabelAlign } = this;
      if (timeData == null) {
        formLabelAlign.begin_time = "";
        formLabelAlign.end_time = "";
      } else {
        formLabelAlign.begin_time = timeData[0];
        formLabelAlign.end_time = timeData[1];
      }
    },
    dataChange(event) {
      let events = event[event.length - 1];
      this.formLabelAlign.cate_id = events;
    },
    //点击保存
    on_preserve(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let { formLabelAlign, id } = this;
          if (id) {
            formLabelAlign.id = id;
          }
          if(this.img_List)
            formLabelAlign.img = this.img_List[0]
          // let department_id = this.$refs.BepartmentMulti.way_pitch();
        
          // if (!department_id) {
          //   this.$message({
          //     message: "请选择部门",
          //     type: "warning",
          //   });
          //   return
          // }

          // formLabelAlign.department_id = department_id;
          eductionsave(formLabelAlign).then((res) => {
            if (res.code == 0) {
              this.$message({
                message: res.msg,
                type: "success",
              });
              let that = this;
              that.$emit("way_tableData");
              setTimeout(function () {
                that.route_go();
              }, 1000);
            }
          });
        } else {
          return false;
        }
      });
    },
    //返回
    route_go() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="less" scoped>
.from {
  display: flex;
  flex-wrap: wrap;
  flex: 1;
}
.form-inp {
  width: 300px;
  height: 32px;
}
.form-textarea {
  width: 800px;
}
.pop-inp {
  width: 150px;
}
.tag-sty {
  width: 300px;
  display: flex;
  flex-direction: column;
}
.selectlength {
  color: #515a6e;
  border: 1px solid #abdcff;
  background-color: #f0faff;
  padding: 10px 20px;
  font-size: 14px;
  margin: 20px 0;
  border: 5px;
  span {
    color: rgb(45, 140, 240);
  }
}
.search_button {
  display: flex;
  margin-bottom: 18px;
  flex-direction: column-reverse;
}
.botton-an {
  text-align: center;
  margin-top: 10px;
}
.pop-img {
  margin: 0 5px 10px 0;
  width: 40px;
  height: 40px;
}
.qui {
  height: 400px;
  margin-bottom: 40px;
}
.execute {
  display: flex;
}
.execute /deep/ .el-input__inner {
  height: 32px;
  margin-top: 1px;
}
.el-col-12 {
  height: 50px;
}
</style>